<template>
  <div class="banner">
    <swiper :options="swiperOption" ref="mySwiper" v-if='banner.length>0'>
      <swiper-slide class="banner-item" v-for="(item, index) in banner" :key="index">
            <a :href="item.link">
                <img class='swiper-img' data-swiper-parallax="50%" :src="item.images" alt="">
                <img v-if="item.textimages" class="swiper-min-imgs" :src="item.textimages" alt=""/>
            </a>
        </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        swiper,
        swiperSlide
    },
    props: {
        banner:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            swiperOption: {
                loop: true,
                speed:1000,
                parallax : true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    // type: 'custom',
                    // renderCustom: function (swiper, current, total) {
                        
                    //     let numble = 0;
                    //     let currentIndex = 0;
                    //     current >= 10 ? currentIndex = current : currentIndex = '0'+current;
                    //     total >= 10 ? numble = `<em>${currentIndex}</em> / ${total}` : numble = `<em>0${current}</em> / 0${total}`;
                    //     return numble;
                    // }
                },
            }
        };
    },
    computed: {
        swiper() {
         return this.$refs.mySwiper.swiper;
        }
    },
   
};
</script>

<style lang="less">
    .banner{
        .swiper-pagination{
            // left: auto;
            // right: 50%;
            // margin-right: -780px;
            bottom: 42px;
            display: flex;
            justify-content: center;
            color: #fff;
            font-size: 25px;
            line-height: 1;
            .swiper-pagination-bullet{
                border-radius: 0;
                width: 30px;
                height: 5px;
                background: #fff;
                cursor: pointer;
                opacity: 1;
                transition: 0.38s;
                &.swiper-pagination-bullet-active{
                    background: #e50012;
                }
            }
        }
    }
    .banner .swiper-pagination .first{
        display: inline-block; 
        font-size: 34px;
    }
    .banner-item {
        position: relative;
        overflow: hidden;
        .swiper-img{
            display: block;
            width: 100%;
            height: calc(100vh - 90px);
            object-fit: cover;
        }
        .swiper-min-imgs{
            position: absolute;
            left: 50%;
            margin-left: -780px;
            top:50%;
            transform: translateY(-50%);
            width: 24.375%;
        }
    }
    .banner__text{
        position: absolute;
        
        top: 50%;
        z-index: 3;
        margin-top: -100px;
        text-align: center;
        strong{
            display: block;
            color: #fff;
            font-size: 50px;
            line-height: 1.3;
            font-weight: normal;
            &:nth-child(2){
                padding-left: 120px;
            }
        }
        small{
            display: block;
            margin-top: 20px;
            color: #ffffff;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-align: center;
        }
    }
</style>>
