<template>
  <div class="logistics inner clearfix">
      <div class="logistics__img" v-animate-onscroll="{down: 'animated fadeInUp'}" :style="{backgroundImage:`url(${logistics.images})`}">
        <router-link to="/about/party">党建专栏<i></i></router-link>
      </div>
      <div class="logistics__content" v-animate-onscroll="{down: 'animated fadeInUp'}">
          <div class="logistics__tt">
              <strong class="pc">{{logistics.title +' · '+logistics.title2}}</strong>
              <p class="ip"><strong>{{logistics.title}}</strong><strong>{{logistics.title2}}</strong></p>
              <b>{{logistics.sologn}}</b>
          </div>
          <ul class="logistics-list">
              <li v-for="(item, index) in logistics.canshu" :key="index">
                  <b></b>
                  <span>{{item.k}}</span>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
export default {
    props: ['logistics'],
    data(){
        return {
        }
    }
}
</script>

<style lang="less">
.logistics{
    padding:50px 0 54px;
}
.logistics__img{
    float: right;
    position: relative;
    width: 44.23%;
    height: 310px;
    background-position: center;
    background-size: cover;
    a{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 46px;
        display: block;
        padding: 0 18px 0 20px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        background: #e5171f;
        color: #fff;
        font-size: 14px;
        &:hover{
            i{
                left: 3px;
            }
        }
        i{
            display: inline-block;
            position: relative;
            left: 0;
            top: -1px;
            margin-left: 12px;
            width: 18px;
            height: 18px;
            border: 1px solid #fff;
            border-radius: 50%;
            vertical-align: middle;
            background: url(../../assets/arrow1.png) center no-repeat;
            transition: 0.3s;
        }
    }
}
.logistics__content{
    width: 47.756%;
}
.logistics__tt{
    display: inline-block;
    margin-bottom: 60px;
    text-align: center;
    .ip{
        display: none;
    }
    strong{
        display: block;
        margin-bottom: 35px;
        line-height: 1.2;
        color: #333333;
        font-size: 38px;
        font-weight: normal;
    }
    b{
        display: inline-block;
        padding: 13px 36px;
        background: #eeeeee;
        color: #333333;
        font-size: 24px;
        font-weight: normal;
    }
}
.logistics-list{
    display: flex;
    margin-left: -60px;
    margin-right: -60px;
    li{
        text-align: center;
        flex: 1;
        border-right: 1px dotted #000000;
        &:last-child{
            border-right: none;
        }
        
        &:nth-child(1){
            b{
                background: url(../../assets/party1.png) center no-repeat;
            }
        }
        &:nth-child(2){
            b{
                background: url(../../assets/party2.png) center no-repeat;
            }
        }
        &:nth-child(3){
            b{
                background: url(../../assets/party3.png) center no-repeat;
            }
        }
        &:nth-child(4){
            b{
                background: url(../../assets/party4.png) center no-repeat;
            }
        }
        b{
            display: block;
            position: relative;
            margin: auto;
            width: 50px;
            height: 43px;
            transition: 0.58s;
            img{
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        span{
            display: block;
            margin-top: 18px;
            color: #333333;
            font-size: 18px;
            text-align: center;
            line-height: 1;
            transition: 0.38s;
        }
    }
}
</style>