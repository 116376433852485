<template>
  <div class="business__area" >
       <div class="business__area__top">
          <div class="business__title" v-animate-onscroll="{down: 'animated fadeInUp'}">
              <strong>{{business.name}}</strong>
              <small>{{business.ename}}</small>
          </div>
          <div class="business__text" v-animate-onscroll="{down: 'animated fadeInUp'}">
              <div class="business__wr">
                  <div class="business_items" v-show="NowIndex == index" v-for="(items, index) in business.list" :key="items.id">
                    <p v-html="items.description"></p>
                    <router-link :to="`/business/businessDetail/${items.cid}`">查看更多</router-link>
                  </div>
                  
              </div>
              
          </div>
      </div>
      <div class="business__swiper" >
           <swiper :options="swiperOption" v-if="business.list" ref="businessSwiper">
                <swiper-slide class="business-itemd" v-for="(item, index) in business.list" :key="index">
                    <div class="business" data-swiper-parallax="50%" :style="{backgroundImage:`url(${item.indexthumb})`}"></div>
                </swiper-slide>
                
            </swiper>
            <div class="swiper-load"><i></i></div>
      </div>
     
      <div class="business__list" v-animate-onscroll="{down: 'animated fadeInUp'}">
          <ul class="business__in">
              <li v-for="(item, index1) in business.list" :key="index1" :class="NowIndex == index1?'active':''"  @click="IsActive(index1)">
                  <b><img :src="item.indexicon" alt=""/></b>
                  <span>{{item.title}}</span>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    props: {
        business: {
           type: [Array, Object],
           
        }
    },
    data(){
        const self = this
        return {         
            NowIndex: 0,
            swiperOption: {
                loop: true,
                speed:1000,
                parallax : true,
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                on:{
                    transitionStart: function(){  
                        self.NowIndex = this.realIndex;
                        $('.swiper-load i').css({width:0}).stop().animate({width:100+'%'},4000,'linear');
                    },
                },
            }
        }
    },
    components: {
        swiper,
        swiperSlide
    },
    computed: {
        swiper() {
            return this.$refs.businessSwiper.swiper;
        }
    },
    methods: {
        IsActive(k) {
            this.NowIndex = k+1;
            this.$refs.businessSwiper.swiper.slideTo(k+1, 1000, false);
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="less">
.business__area{
    position: relative;
    padding-bottom: 46px;
}
.business-itemd{
    height: 100%;
    overflow: hidden;
}
.business__swiper{
    >.swiper-container{
        height: 100%;
    }
}
.business{
    widows: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.business__area__top{
    padding: 106px 0 138px;
    text-align: center;
}
.business__title{
    position: relative;
    padding-bottom: 36px;
    &:before{
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: 0;
        content: "";
        width: 80px;
        height: 4px;
        background: #fff;
    }
    strong{
        display: block;
        line-height: 1;
        color: #fff;
        font-size: 38px;
        font-weight: normal;
    }
    small{
        display: block;
        margin-top: 23px;
        line-height: 1;
        color: #fff;
        font-size: 36px;
        font-family: "Times New Roman";
        text-transform: uppercase;
    }
}
.business__text{
    text-align: center;
    margin-top: 20px;
    p{
        margin: auto;
        width: 780px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        max-width: 100%;
        height: 120px;
    }
    a{
        display: block;
        margin: auto;
        // margin-top: 60px;
        width: 120px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        background: #e03021;
        font-size: 16px;
    }
}
.business__list{
    width: 100%;
    text-align: center;
    line-height: 0;
}
.business__in{
    display: inline-block;
    li{
        float: left;
        position: relative;
        z-index: 3;
        margin-right: 7px;
        width: 195px;
        text-align: center;
        cursor: pointer;
        padding: 26px 0 20px;
        background: rgba(0,0,0,0.4);
        transition: 0.45s;
        &:before{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            content: "";
            width: 100%;
            height: 0;
            background: #e5171f;
            transition: 0.5s;
        }
        &:hover,&.active{
            &:before{
                height: 100%;
                bottom: auto;
                top: 0;
            }
        }
        &:hover{
            b{
                transform: translateY(-3px);
            }
        }
        &:last-child{
            margin-right: 0;
        }
        b{
            display: block;
            position: relative;
            margin: auto;
            width: 40px;
            height: 34px;
            margin-bottom: 20px;
            line-height: 1;
            color: #fff;
            font-size: 38px;
            font-weight: normal;
            transition: 0.3s;
            img{
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        span{
            display: block;
            line-height: 1;
            color: #fff;
            font-size: 16px;
        }
    }
}
.business__swiper{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.swiper-load{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    z-index: 10;
    background: rgba(224,48,33,0.3);
    i{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 20%;
        height: 100%;
        background: #e03021;
    }
}
</style>