<template>
  <footer class="footer" id="footer">
    <div class="footer-top" :style="{backgroundImage:`url(${footerbg})`}">
        <div class="footer-left">
            <div class="hot-line">
                <strong>客户服务热线</strong>
                <b>{{footer.hottel}}</b>
                <small>Customer Service Hotline</small>
            </div>
            <div class="footer-map"><img :src="map" alt=""/></div>
            <div class="address">
                <!-- <p><span>电话：</span><b>{{footer.tel}}</b></p> -->
                <!-- <p><span>传真号：</span><b>{{footer.fax}}</b></p> -->
                <!-- <p><span>email：</span><b>{{footer.email}}</b></p> -->
                <p><span>地址：</span><b>{{footer.address}}</b></p>
            </div>
        </div>
        <div class="footer-eq">
            <div class="eq-item">
                <figure><img :src="footer.wb_code" alt=""/></figure>
                <span>{{footer.wb_code_title}}</span>
            </div>
            <!-- <div class="eq-item">
                <figure><img :src="footer.wx_code" alt=""/></figure>
                <span>{{footer.wx_code_title}}</span>
            </div> -->
        </div>
    </div>
    <div class="footer-copy">
        <div class="copy">
            <p>{{footer.copyright}}</p>
            <p>{{footer.gnicpc}}</p>
            <a target="_block" href="https://www.28jw.cn/">技术支持：</a><a target="_block" href="https://www.28jw.cn/">今网科技</a>
        </div>
        <div class="footer-oa">
            <!-- <ul class="footer-navs">
                <li><a href="#">投诉建议</a></li>
                <li><a href="#">常见问题</a></li>
                <li><a href="#">法律申明</a></li>
                <li><a href="#">站点地图</a></li>
            </ul> -->
            <div class="copy">
                <p><a target="_block" href="https://beian.miit.gov.cn">蜀ICP备2023013433号-1</a></p>
                <a target="_block" href="https://beian.mps.gov.cn/#/query/webSearch">公安局备案号5115000334 </a>
            </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: "footers",
    data() {
        return {
            footerbg: require('../assets/footerbg.jpg'),
            map: require('../assets/map.png'),
            footer: []
        }
    },
    methods: {
        async getFooter(){
           const {data: res} = await this.$http.post("getSetInfo");
          this.footer = res.data;
        }
    },
    mounted() {
        this.getFooter();
    }
}
</script>

<style lang="less">
    .footer-top{
        padding: 44px 9.375% 44px;
        background-size: cover;
        background-position: center; 
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .footer-left{
        display: flex;
        align-items: flex-start;
    }
    .hot-line{
        margin-top: 10px;
        padding-top: 2px;
        padding-left: 26px;
        border-left: 2px solid #cf000e;
        strong{
            display: block;
            margin-bottom: 18px;
            line-height: 1;
            color: #fff;
            font-size: 20px;
            font-weight: normal;
        }
        b{
            display: block;
            margin-bottom: 24px;
            line-height: 1;
            color: #cf000e;
            font-size: 28px;
        }
        small{
            display: block;
            color: #cacaca;
            font-size: 12px;
        }
    }
    .footer-map{
        margin-top: 5px;
        img{
            display: block;
            width: 257px;
            height: auto;
        }
    }
    .address{
        margin-left: 58px;
        margin-top: 20px;
        p{
            color: #ffffff;
            font-size: 14px;
            line-height: 30px;
            b{
                font-weight: normal;
            }
        }
    }
    .footer-eq{
        display: flex;
        margin-top: 12px;
    }
    .eq-item{
        margin-left: 25px;
        &:first-child{
            margin-left: 0;
        }
        figure{
            display: block;
            padding: 10px;
            width: 76px;
            line-height: 1;
            border: 1px solid #fff;
            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }
        span{
            display: block;
            margin-top: 9px;
            text-align: center;
            line-height: 1;
            color: #fff;
            font-size: 14px;
        }
    }
    .footer-copy{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 9.375%;
        background: #121212;
    }
    .copy{
        display: flex;
        p{
            margin-right: 10px;
        }
        p,a{
            color: #ffffff;
            font-size: 14px;
        }
        a:hover{
            color: #cf000e;
        }
    }
    .footer-oa{
        display: flex;
        align-items: center;
    }
    .footer-navs{
        display: flex;
        margin-right: 15px;
        li{
            padding: 0 5px;
            line-height: 1;
            border-left: 2px solid #d2d2d2;
            &:first-child{
                border-left: none;
            }
            a{
                display: inline-block;
                color: #ffffff;
                font-size: 14px;
                line-height: 15px;
                &:hover{
                    color: #615657;
                }
            }
        }
    }
    .footer-o{
        display: flex;
        a{
            display: block;
            margin-right: 16px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            text-align: center;
            line-height: 34px;
            color: #fff;
            font-size: 16px;
            background: #cf000e;
            &:last-child{
                margin-right: 0px;
            }
            &:first-child{
                background: #cf000e url(../assets/iphone.png) center no-repeat;
            }
        }
    }
</style>