<template>
  <div>
    <Banner :banner="bannerList"/>
    <News :news ="news"/>
    <About :about="about"/>
    <Business :business="business"/>
    <Logistics :logistics="logistics"/>
    <Partner :partner="partner"/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Banner from '../components/home/Banner';
import HNews from '../components/home/News';
import Habout from '../components/home/AboutHome';
import Hbusiness from '../components/home/BusinessHome';
import Logistics from '../components/home/Logistics';
import Partner from '../components/home/Partner';

export default {
  components: {
    Banner,
    News: HNews,
    About: Habout,
    Business: Hbusiness,
    Logistics,
    Partner,
    Footer
  },
  data() {
    return {
      bannerList:[],
      news: [],
      about: [],
      business:[],
      logistics:[],
      partner: []
    }
  },
  methods: {
        async getHomeData(){
            const {data: res} = await this.$http.get("getIndexInfo");
            this.bannerList = res.data.banner;
            this.news = res.data.news;
            // console.log(this.news)
            this.about = res.data.about;
            this.business = res.data.business;
            this.logistics = res.data.server;
            this.partner = res.data.partner;
        }
    },
    mounted() {
        this.getHomeData();
    }
}
</script>

<style lang="less">

</style>