<template>
  <div class="home__about" :style="{backgroundImage:`url(${Aboutbg})`}">
      <div class="home__about__wrap inner clearfix">
          <div class="about__video" v-animate-onscroll="{down: 'animated fadeInUp',up: 'animated fadeInUp'}">
            <video id="video" ref="movie" preload="auto" :src="about.video" :poster="about.images" x5-video-player-type="h5" x5-video-player-fullscreen="true" x-webkit-airplay="allow" webkit-playsinline playsinline controls controlsList="nodownload">
                <source :src="about.video" type="video/mp4">
			</video>
            <div class="video__btn" v-show="offOn" :class="{'play': !offOn}" @click="getVideoplay">
                <div class="video__an"><i></i></div>
                <span>播放视频</span>
                <!-- <span v-else>暂停视频</span> -->
            </div>
          </div>
          <div class="about__content" v-animate-onscroll="{down: 'animated fadeInUp',up: 'animated fadeInUp'}">
              <div class="about__title">
                  <small>{{about.en_title}}</small>
                  <strong>{{about.title}}</strong>
              </div>
              <div class="about__text">
                  <p>{{about.content}}</p>
              </div>
              <div class="about__data clearfix">
                  <div class="about__data__item" v-for="(item1, index) in about.canshu" :key="index">
                      <strong>{{item1.k}}<i>+</i></strong>
                      <p>{{item1.v}}</p>
                  </div>
              </div>
              <div class="about__more">
                  <router-link to="/about/about">查看更多</router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            Aboutbg: require('../../assets/2.jpg'),
            offOn: true
        }
    },
    props: ['about'],
    methods:{
        getVideoplay: function(){
            
            var video1=document.getElementById("video");
            if(this.offOn){
                video1.play();
            } else{
                video1.pause();
            }
            this.offOn = !this.offOn;
        },
        handPlay() {
           this.offOn = false;
        },
        handPause() {
            this.offOn = true;
        }
    },
    mounted() {
        this.$refs.movie.addEventListener("play", this.handPlay);
        this.$refs.movie.addEventListener("pause", this.handPause);
    }
}
</script>

<style lang="less">
    .home__about{
        padding: 88px 0;
        background-position: center;
        background-size: cover;
    }
    .about__video{
        float: right;
        position: relative;
        width: 46.8%;
        height: 456px;
        &.fadeInUp{
            animation-delay: 0.35s;
        }
        video{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .video__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
        &.play{
            .video__an{
                i{
                    background: url(../../assets/stop.png) center no-repeat;
                }
            }
        }
        span{
            display: block;
            margin-top: 14px;
            color: #fff;
            line-height: 1;
            font-size: 20px;
        }
    }
    .video__an{
        position: relative;
        width: 54px;
        height: 54px;
        margin: auto;
        &:hover{
            &:before{
                transform: scale(1.15);
            }
        }
        i{
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -5px;
            margin-top: -8px;
            z-index: 2;
            width: 11px;
            height: 16px;
            background: url(../../assets/play.png) center no-repeat;
        }
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(255,255,255,0.5);
            transition: 0.45s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        &:after{
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -22px;
            margin-top: -22px;
            content: "";
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: #fff;
        }
    }
    .about__content{
        float: left;
        width: 46.15%;
    }
    .about__title{
        padding: 35px 0 26px;
        small{
            display: block;
            margin-bottom: 18px;
            line-height: 1;
            color: #e03021;
            font-size: 36px;
            font-family: "Times New Roman";
            text-transform: uppercase;
        }
        strong{
            display: block;
            letter-spacing: 2px;
            line-height: 1;
            color: #333333;
            font-size: 26px;
            font-weight: normal;
        }
    }
    .about__text{
        margin-bottom: 55px;
        p{
            color: #777777;
            font-size: 14px;
            line-height: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
    .about__data__item{
        float: left;
        margin-right: 72px;
        &:last-child{
            margin-right: 0;
        }
        strong{
            display: block;
            line-height: 1;
            color: #333333;
            font-size: 48px;
            font-weight: normal;
            font-family: "Roboto-Light";
            i{
                display: inline-block;
                margin-left: 4px;
                color: #333333;
                font-style: normal;
            }
        }
        p{
            margin-top: 15px;
            color: #777777;
            font-size: 14px;
        }
    }
    .about__more{
        margin-top: 60px;
        a{
            display: inline-block;
            position: relative;
            padding-right: 20px;
            color: #666666;
            font-size: 16px;
            &:hover{
                color: #c6243b;
            }
            &:before{
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -6px;
                content: "";
                width: 13px;
                height: 12px;
                background:url(../../assets/aboutMore.png) center no-repeat;
            }
        }
    }
</style>