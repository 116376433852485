var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home__news"},[_c('div',{staticClass:"inner clearfix"},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
        down: 'animated fadeInLeft',
        up: 'animated fadeInLeft',
      }),expression:"{\n        down: 'animated fadeInLeft',\n        up: 'animated fadeInLeft',\n      }"}],staticClass:"news__swiper"},[(_vm.Hotnew.length > 0)?_c('swiper',{ref:"newsList",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.news.slice(0, 6)),function(item,index){return _c('swiper-slide',{key:index,staticClass:"swiper__news"},[(item.catid == 8)?_c('router-link',{attrs:{"to":'/companynews/' + item.cid}},[_c('figure',{attrs:{"data-swiper-parallax":"50%"}},[_c('img',{staticClass:"swiper-img",attrs:{"src":item.thumb,"alt":""}})]),_c('div',{staticClass:"news__name"},[_c('span',[_vm._v(_vm._s(item.title))])])]):(item.catid == 11)?_c('router-link',{attrs:{"to":'/party/' + item.cid}},[_c('figure',{attrs:{"data-swiper-parallax":"50%"}},[_c('img',{staticClass:"swiper-img",attrs:{"src":item.thumb,"alt":""}})]),_c('div',{staticClass:"news__name"},[_c('span',[_vm._v(_vm._s(item.title))])])]):(item.catid == 12)?_c('router-link',{attrs:{"to":'/partyact/' + item.cid}},[_c('figure',{attrs:{"data-swiper-parallax":"50%"}},[_c('img',{staticClass:"swiper-img",attrs:{"src":item.thumb,"alt":""}})]),_c('div',{staticClass:"news__name"},[_c('span',[_vm._v(_vm._s(item.title))])])]):_vm._e()],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e()],1),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
        down: 'animated fadeInRight',
        up: 'animated fadeInRight',
      }),expression:"{\n        down: 'animated fadeInRight',\n        up: 'animated fadeInRight',\n      }"}],staticClass:"dynamic"},[_c('div',{staticClass:"dynamc__fix"},[_vm._m(0),_c('ul',{staticClass:"dynamic__list"},_vm._l((_vm.news.slice(0, 8)),function(item){return _c('li',{key:item.cid},[(item.catid == 8)?_c('router-link',{attrs:{"to":'/companynews/' + item.cid}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('time',[_vm._v(_vm._s(item.create_time))])]):(item.catid == 11)?_c('router-link',{attrs:{"to":'/party/' + item.cid}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('time',[_vm._v(_vm._s(item.create_time))])]):(item.catid == 12)?_c('router-link',{attrs:{"to":'/partyact/' + item.cid}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('time',[_vm._v(_vm._s(item.create_time))])]):_vm._e()],1)}),0),_c('div',{staticClass:"new__dymanic"},[_c('router-link',{attrs:{"to":"/companynews/companynews"}},[_vm._v(" 更多资讯"),_c('i')])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dynamic__title clearfix"},[_c('strong',[_vm._v("新闻资讯")]),_c('small',[_vm._v("news")])])
}]

export { render, staticRenderFns }