<template>
  <div class="home__news">
    <div class="inner clearfix">
      <div
        class="news__swiper"
        v-animate-onscroll="{
          down: 'animated fadeInLeft',
          up: 'animated fadeInLeft',
        }"
      >
        <swiper :options="swiperOption" ref="newsList" v-if="Hotnew.length > 0">
          <swiper-slide
            class="swiper__news"
            v-for="(item, index) in news.slice(0, 6)"
            :key="index"
          >
            <router-link v-if="item.catid == 8" :to="'/companynews/' + item.cid">
              <figure data-swiper-parallax="50%">
                <img class="swiper-img" :src="item.thumb" alt="" />
              </figure>
              <div class="news__name">
                <span>{{ item.title }}</span>
              </div>
            </router-link>
            <router-link v-else-if="item.catid == 11" :to="'/party/' + item.cid">
              <figure data-swiper-parallax="50%">
                <img class="swiper-img" :src="item.thumb" alt="" />
              </figure>
              <div class="news__name">
                <span>{{ item.title }}</span>
              </div>
            </router-link>
            <router-link v-else-if="item.catid == 12" :to="'/partyact/' + item.cid">
              <figure data-swiper-parallax="50%">
                <img class="swiper-img" :src="item.thumb" alt="" />
              </figure>
              <div class="news__name">
                <span>{{ item.title }}</span>
              </div>
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div
        class="dynamic"
        v-animate-onscroll="{
          down: 'animated fadeInRight',
          up: 'animated fadeInRight',
        }"
      >
        <div class="dynamc__fix">
          <div class="dynamic__title clearfix">
            <strong>新闻资讯</strong>
            <small>news</small>
          </div>
          <ul class="dynamic__list">
            <li v-for="item in news.slice(0, 8)" :key="item.cid">
              <router-link v-if="item.catid == 8" :to="'/companynews/' + item.cid">
                <span>{{ item.title }}</span>
                <time>{{ item.create_time }}</time>
              </router-link>
              <router-link v-else-if="item.catid == 11" :to="'/party/' + item.cid">
                <span>{{ item.title }}</span>
                <time>{{ item.create_time }}</time>
              </router-link>
              <router-link v-else-if="item.catid == 12" :to="'/partyact/' + item.cid">
                <span>{{ item.title }}</span>
                <time>{{ item.create_time }}</time>
              </router-link>
            </li>
          </ul>
          <div class="new__dymanic">
            <router-link to="/companynews/companynews">
              更多资讯<i></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    news: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      Hotnew: [],
      Newslist: [],
      swiperOption: {
        loop: true,
        speed: 800,
        parallax: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.newsList.swiper;
    },
  },
  methods: {
    async Hotnews() {
      const { data: res } = await this.$http.post("getNewsList", {
        params: {
          attr: "scroll",
        },
      });
      

      if (res.info == "获取成功") {
        this.Hotnew = res.data.list;
      }

      const { data: res1 } = await this.$http.post("getNewsList", {
        params: {
          catid: 4,
        },
      });
      this.Newslist = res1.data.list;
    },
  },
  mounted() {
    this.Hotnews();
  },
};
</script>

<style lang="less">
.home__news {
  padding: 90px 0;
  background: #f5f5f5;
  overflow: hidden;
}
.animated {
  opacity: 0;
}
.news__swiper {
  float: left;
  width: 42.3%;
  opacity: 0;
  .swiper-pagination {
    width: auto !important;
    left: auto !important;
    right: 18px;
    bottom: 26px !important;
    .swiper-pagination-bullet {
      margin: 0 6px;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #cf0005;
      }
    }
  }
}
.swiper__news {
  overflow: hidden;
  figure {
    display: block;
    position: relative;
    line-height: 1;
    height: 0;
    padding-bottom: 373px;
    img {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.news__name {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  //background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
  span {
    display: block;
    position: absolute;
    left: 32px;
    right: 90px;
    bottom: 25px;
    line-height: 1;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
  }
}
.dynamic {
  position: relative;
  float: right;
  width: 53.17%;
  opacity: 0;
}
.dynamc__fix {
  padding-right: 170px;
}
.dynamic__title {
  margin-bottom: 27px;
  strong {
    display: block;
    float: left;
    line-height: 1;
    color: #333333;
    font-size: 38px;
    font-weight: normal;
  }
  small {
    display: block;
    float: left;
    margin-left: 16px;
    margin-top: 7px;
    color: #e5171f;
    font-size: 36px;
    font-family: "Times New Roman";
    line-height: 1;
    text-transform: uppercase;
  }
}
.dynamic__list {
  li {
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        span {
          color: #e5171f;
        }
      }
      span {
        display: block;
        position: relative;
        padding-left: 14px;
        color: #333333;
        font-size: 16px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.38s;
        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -2px;
          content: "";
          width: 4px;
          height: 4px;
          background: #e5171f;
        }
      }
      time {
        display: block;
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
.new__dymanic {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  a {
    display: block;
    position: relative;
    padding-top: 26px;
    height: 92px;
    width: 118px;
    background: #aeaeae;
    text-align: center;
    line-height: 1;
    color: #ffffff;
    font-size: 18px;
    transition: 0.45s;
    &:hover {
      background: #e03021;
      i {
        transform: rotate(180deg);
      }
    }
    i {
      display: block;
      position: absolute;
      right: 23px;
      bottom: 24px;
      width: 18px;
      height: 18px;
      transition: 0.4s;
      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        content: "";
        width: 100%;
        height: 2px;
        background: #fff;
      }
      &:after {
        position: absolute;
        left: 50%;
        margin-left: -1px;
        top: 0;
        content: "";
        width: 2px;
        height: 100%;
        background: #fff;
      }
    }
  }
}
</style>